<script>
import { mapState, mapActions } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'
import PaginationCustom from '@/components/table/Pagination.vue'
import mixinHelper from '@/mixins/helper'

export default {
   name: 'User',
   components: {
      BCardCode,
      VueGoodTable,
      PaginationCustom,
   },
   mixins: [mixinHelper],
   data() {
      return {
         item: {},
         mode: 'INSERT',
         form: {
            name: null,
            username: null,
            password: null,
            status: null,
            role_id: null,
         },
         required,
         pageLength: 10,
         pageOptions: ['10', '25', '50', '100'],
         searchTerm: '',
         columns: [
            {
               label: 'Nama',
               field: 'name',
               sortable: false,
               thClass: 'text-center align-middle',
               filterOptions: {
                  enabled: true,
                  placeholder: 'Cari',
               },
            },
            {
               label: 'Username',
               field: 'username',
               sortable: false,
               thClass: 'text-center align-middle',
               filterOptions: {
                  enabled: true,
                  placeholder: 'Cari',
               },
            },
            {
               label: 'Role',
               field: 'role',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Status',
               field: 'status',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Action',
               field: 'action',
               sortable: false,
               thClass: 'text-center align-middle',
            },
         ],
         options: {
            status: [
               { value: null, text: '-' },
               { value: true, text: 'Aktif' },
               { value: false, text: 'Tidak Aktif' },
            ],
         },
      }
   },
   computed: {
      ...mapState({
         myData: state => state.User.items,
         myRoles: state => state.User.roles,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
      statusUser() {
         const data = {
            true: 'Aktif',
            false: 'Tidak Aktif',
         }
         return status => (data[status] ? data[status] : status)
      },
      roleUser() {
         const items = []
         this.myRoles.map(item => items.push({
            value: item.id,
            text: item.nama,
         }))
         return items
      },
   },
   mounted() {
      this.loadItems()
      this.getRoles()
   },
   methods: {
      ...mapActions({
         getData: 'User/getData',
         getRoles: 'User/getRoles',
         getItem: 'User/getItem',
         postItem: 'User/postItem',
         updateItem: 'User/updateItem',
         deleteItem: 'User/deleteItem',
         resetPassword: 'User/resetPassword',
         logoutItem: 'User/logoutItem',
         logoutAll: 'User/logoutAll',
      }),
      onAdd() {
         this.resetForm()
         this.$bvModal.show('modal-form')
      },
      onEdit(id) {
         this.getItem(id).then(item => {
            this.form = item
            this.mode = 'UPDATE'
            this.$bvModal.show('modal-form')
         })
      },
      onDelete(id) {
         this.$swal({
            title: 'Apakah anda yakin?',
            text: 'Data tidak dapat dipulihkan kembali!',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: 'Ya, Hapus!',
            html: false,
            allowOutsideClick: false,
            customClass: {
               confirmButton: 'btn btn-danger',
               cancelButton: 'btn btn-outline-dark ml-1',
            },
            buttonsStyling: false,
            preConfirm: () => this.deleteItem(id).then(res => {
               this.loadItems()
               this.$swal('Deleted!', res.message, 'success')
            }).catch(() => {
               this.$swal('Gagal!', '', 'error')
            }),
         })
      },
      onSave() {
         this.$refs.myForm.validate().then(success => {
            console.log(success)
            if (success) {
               if (this.mode === 'INSERT') {
                  this.postItem(this.form).then(res => {
                     this.loadItems()
                     this.$bvModal.hide('modal-form')
                     this.$toast.success(res.message)
                  })
               } else if (this.mode === 'UPDATE') {
                  this.updateItem(this.form).then(res => {
                     this.loadItems()
                     this.$bvModal.hide('modal-form')
                     this.$toast.success(res.message)
                  })
               }
            }
         })
      },
      onResetPassword(id) {
         this.resetPassword(id).then(res => {
            this.$toast.success(res.message)
         })
      },
      onLogout(id) {
         this.logoutItem(id).then(res => {
            this.$toast.success(res.message)
         })
      },
      onLogoutAll() {
         this.logoutAll().then(res => {
            this.$toast.success(res.message)
         })
      },
      resetForm() {
         this.form = {
            name: null,
            username: null,
            password: null,
            status: null,
            role_id: null,
         }
         this.mode = 'INSERT'
      },
      onDetail(id) {
         this.item = this.myData.find(item => item.id === id)
         this.$bvModal.show('modal-detail')
      },
      onFilter() {
         setTimeout(() => {
            this.loadItems()
         }, 100)
      },
      onRefresh() {
         setTimeout(() => {
            this.loadItems()
         }, 100)
      },
      // VUE GOOD TABLE
      async loadItems() {
         await this.getData(this.filter)
      },
   },
}
</script>

<template>
   <div>
      <b-card-code title="Data Pengguna">
         <!-- search input -->
         <b-row>
            <!-- TOOLBAR -->
            <b-col cols="12" md="12">
               <div class="d-flex justify-content-end">
                  <b-button-group class="mb-1 align-self-end">
                     <b-button v-b-tooltip.hover.top="'Tambah'" variant="outline-secondary" size="sm" @click="onAdd()">
                        <feather-icon icon="PlusIcon" />
                     </b-button>
                     <b-button
                        v-b-tooltip.hover.top="'Refresh'"
                        variant="outline-secondary"
                        size="sm"
                        @click="onRefresh()"
                     >
                        <b-spinner v-if="isLoading" small />
                        <feather-icon v-if="!isLoading" icon="RefreshCwIcon" />
                     </b-button>
                     <b-button
                        v-b-tooltip.hover.top="'Logout All'"
                        variant="outline-danger"
                        size="sm"
                        @click="onLogoutAll()"
                     >
                        <feather-icon icon="LogOutIcon" />
                     </b-button>
                     <b-dropdown variant="outline-secondary" right text="Menu" size="sm">
                        <b-dropdown-item @click="onAdd()">
                           Tambah
                        </b-dropdown-item>
                        <b-dropdown-item @click="onLogoutAll()">
                           Logout All
                        </b-dropdown-item>
                        <b-dropdown-item @click="onRefresh()">
                           Refresh
                        </b-dropdown-item>
                     </b-dropdown>
                  </b-button-group>
               </div>
            </b-col>
            <!-- FILTER -->
         </b-row>

         <!-- table -->
         <vue-good-table
            ref="goodTable"
            :columns="columns"
            :rows="myData"
            :line-numbers="true"
            :pagination-options="{
               enabled: true,
               mode: 'pages',
            }"
         >
            <template slot="table-row" slot-scope="props">
               <span v-if="props.column.field == 'name'">
                  <b>{{ props.row.name }}</b>
               </span>
               <span v-if="props.column.field == 'username'">
                  <b>{{ props.row.username }}</b>
               </span>
               <span v-if="props.column.field == 'role'">
                  <b>{{ props.row.role_name }}</b>
               </span>

               <span v-if="props.column.field == 'status'">
                  <center>
                     <b-badge variant="secondary" class="text-white">
                        <small>{{ statusUser(props.row.status) }}</small>
                     </b-badge>
                  </center>
               </span>

               <span v-if="props.column.field == 'action'">
                  <center>
                     <b-button-group>

                        <b-button
                           v-b-tooltip.hover.top="'Detail'"
                           variant="outline-dark"
                           size="sm"
                           @click="onDetail(props.row.id)"
                        >
                           <feather-icon icon="EyeIcon" />
                        </b-button>
                        <b-button
                           v-b-tooltip.hover.top="'Edit'"
                           variant="outline-dark"
                           size="sm"
                           @click="onEdit(props.row.id)"
                        >
                           <feather-icon icon="EditIcon" />
                        </b-button>
                        <b-button
                           v-b-tooltip.hover.top="'Reset Password'"
                           variant="outline-danger"
                           size="sm"
                           @click="onResetPassword(props.row.id)"
                        >
                           <feather-icon icon="RepeatIcon" />
                        </b-button>
                        <b-button
                           v-b-tooltip.hover.top="'Logout'"
                           variant="outline-danger"
                           size="sm"
                           @click="onLogout(props.row.id)"
                        >
                           <feather-icon icon="LogOutIcon" />
                        </b-button>
                        <b-button
                           v-b-tooltip.hover.top="'Delete'"
                           variant="outline-danger"
                           size="sm"
                           @click="onDelete(props.row.id)"
                        >
                           <feather-icon icon="TrashIcon" />
                        </b-button>
                     </b-button-group>
                  </center>
               </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
               <pagination-custom
                  :total="props.total"
                  :page-length="pageLength"
                  :page-options="pageOptions"
                  :page-changed="props.pageChanged"
                  :per-page-changed="props.perPageChanged"
               />
            </template>
         </vue-good-table>
      </b-card-code>

      <b-modal
         id="modal-form"
         size="lg"
         no-close-on-backdrop
         scrollable
         title="Form Pengguna"
      >
         <div style="height: 70vh;">
            <validation-observer ref="myForm">
               <b-form>
                  <b-form-group label="Nama" label-for="name">
                     <validation-provider #default="{ errors }" name="Nama" rules="required">
                        <b-form-input v-model="form.name" :state="errors.length > 0 ? false : true" type="text" />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>
                  <b-form-group label="Username" label-for="username">
                     <validation-provider #default="{ errors }" name="Username" rules="required">
                        <b-form-input v-model="form.username" :state="errors.length > 0 ? false : true" type="text" />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>
                  <b-form-group v-if="mode == 'INSERT'" label="Password" label-for="password">
                     <validation-provider #default="{ errors }" name="Password" rules="required">
                        <b-form-input v-model="form.password" :state="errors.length > 0 ? false : true" type="text" />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>

                  <b-row>
                     <b-col md="6">
                        <b-form-group label="Status" label-for="status">
                           <validation-provider #default="{ errors }" name="Status " rules="required">
                              <b-form-select
                                 v-model="form.status"
                                 :options="options.status"
                                 :state="errors.length > 0 ? false : true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                           </validation-provider>
                        </b-form-group>
                     </b-col>
                     <b-col md="6">
                        <b-form-group label="Role" label-for="role">
                           <validation-provider #default="{ errors }" name="role " rules="required">
                              <b-form-select
                                 v-model="form.role_id"
                                 :options="roleUser"
                                 :state="errors.length > 0 ? false : true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                           </validation-provider>
                        </b-form-group>
                     </b-col>
                  </b-row>
               </b-form>
            </validation-observer>
         </div>
         <template #modal-footer>
            <div class="w-100">
               <b-button
                  v-b-tooltip.hover.top="'Simpan'"
                  variant="primary"
                  type="button"
                  class="float-right"
                  @click.prevent="onSave"
               >
                  <b-spinner v-if="isLoading" small />Simpan
               </b-button>
            </div>
         </template>
      </b-modal>

      <b-modal
         id="modal-detail"
         scrollable
         size="lg"
         hide-footer
         title="Detail"
      >
         <b-list-group>
            <b-list-group-item>
               <b>Nama</b>
               : {{ item.name }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Username</b>
               : {{ item.username }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Role</b>
               : {{ item.role_name }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Status</b>
               :
               <b-badge variant="secondary" class="text-white">
                  <small>{{ statusUser(item.status) }}</small>
               </b-badge>
            </b-list-group-item>
         </b-list-group>
      </b-modal>
   </div>
</template>

<style scoped>
table>tbody>tr>td>span>hr {
   margin-top: 0rem;
   margin-bottom: 0rem;
}

.nowrap {
   white-space: nowrap;
}
</style>
